@import 'variable';

@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
@import 'spinner';
@import 'app';
@import 'header';
@import 'widgets';
@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';
