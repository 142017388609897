/*******************
 Topbar
*******************/
mat-toolbar{
    //box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
    background-color: #19498e !important;

    &.topbar{
        padding: 0px 15px;
    }
    .navbar-header {

        text-align: center;
        .navbar-brand {
            display: flex;
            line-height: 18px;

            align-items: center;
            .dark-logo {
                display: none;

            }
            b {
               margin-right: 5px;
            }
        }
    }
    span{
        color: white !important;
        text-transform: uppercase !important;
        margin: 0px;
        padding: 0px;
    }
    span.user-company{
        font-size: 9px !important;
        text-align: right;
        float: left;
    }
    span.user-name {
        text-transform: uppercase;
        font: $bodyfont $bodyfont-size;
        font-size: 11px;
        float: left;
    }

    .app-search{
        display: none;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;
        position: absolute;
        .form-control{
            line-height: 42px;

        }
        .cl-srh-btn{
            position: absolute;
            top: 12px;
            right: 15px;
            font-size: 15px;
            color:$bodytext;
            cursor: pointer
        }
    }
    .profile-pic{
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }

    .user-info {

        height: 50px;
        width: auto;
        text-align: right;
        float: left;
        padding-top: 17px;
        font: $bodyfont $bodyfont-size;
        font-weight: 400;

        .name {

            font-size: 11px;
            line-height: 15px;
        }

        .company {

            font-size: 9px;
            line-height: 13px;
        }
    }

    .user-photo {
        cursor: pointer;
        margin: 0px 15px 0px 5px;
        padding-top: 10px;
        float: left;
    }
}
/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
  height: 50px !important;
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

