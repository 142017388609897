// font family, also paste this import into styles.css file.
@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,600,700');

$bodyfont: 'Ubuntu', 'Poppins', sans-serif;
$bodyfont-size: 12px;
$headingfont: 'Ubuntu', 'Poppins', sans-serif;

$custom-palette: (
    50 : #e8e8ec,
    100 : #c7c6ce,
    200 : #a1a1ae,
    300 : #7b7b8e,
    400 : #5f5e75,
    500 : #004A8F,
    600 : #3d3c55,
    700 : #34334b,
    800 : #2c2b41,
    900 : #1e1d30,
    A100 : #7777ff,
    A200 : #4444ff,
    A400 : #1111ff,
    A700 : #0000f6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/*Material Theme Colors*/

$tab-header-color: #73757E;
$tab-header-background: #FAFAFA;
$primary: mat.define-palette($custom-palette, 500);
$accent: mat.define-palette(mat.$indigo-palette, 500);
$warn: mat.define-palette(mat.$pink-palette, 500);
$theme: mat.define-light-theme((
  color: (
   primary: $primary,
   accent: $accent,
   warn: $warn,
 ),
 typography: mat.define-typography-config(
    $font-family: 'Ubuntu, Poppins, sans-serif',
  )
));
$background: map.get($theme, background);
$foreground: map.get($theme, foreground);

// @include mat.core-theme($theme);

// $custom-typography: mat.define-typography-config(
//   $font-family: 'Source Sans Pro, Poppins'
// );
// Override the typography in the core CSS.
// @include mat.core-theme($custom-typography);
// Default Theme
@include mat.all-component-themes($theme);

/*Theme Colors*/
$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#f2f6f8;
$bodycolor: #ffffff;
$headingtext: #43425d;
$bodytext: #67757c;
$sidebar-text: #FFFFFF;
$sidebar-icons: #A5A4BF ;

$font-16: 16px;

$light-text: #a6b7bf;
$grey-text: #4D4F5C;
$themecolor: #1e88e5;
$themecolor-alt: #7777ff;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #19498e;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$icon-grey-color: #A4AFB7;
$link-hover: #A4AFB7;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;
$grey: #4D4F5C;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

/*Custom Variable*/
$red-clean-button: #990000;
$grey-disabled: #b2b4b55c;

